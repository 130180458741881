import React, {Component} from "react"
import Slider from 'react-slick'

import Layout from "../components/layout"
import SEO from "../components/seo"

import propertyStreetView from '../images/property_street_view.jpg'
import propertyBalcony from '../images/property_balcony.jpg'
import logo from '../images/logo.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us. A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <h3>Register for updates</h3>
        <input type='text' name='name' placeholder='Name' onChange={this.handleChange} required />
        <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required />
        <input type='text' name='phone' placeholder='Phone' onChange={this.handleChange} required />
        <button type='submit'>Send</button>
      </form>
    )
  }
}

class IndexPage extends Component {

  render() {
    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
    }
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

        <section className='landing__splash'>
          <header>
            <nav>
              <ul>
                <li><a href='#register'>Register</a></li>
                <li><a href='#contact'>Contact</a></li>
              </ul>
            </nav>
          </header>
          <div className='landing__splash__inner'>
            <div className='landing__splash__content'>
              <div className='landing__splash__content__inner'>
                <img className="landing__logo" src={logo} alt='Glen Iris - Artistry Collective' />
                <p className='text-1'>IMPECCABLE DESIGN + CRAFTMANSHIP</p>
                <p className='text-2'>Coming Soon</p>
                <p className='text-3'>Only 5 ultra luxe 3 & 4 bedroom homes with private lifts. Register now to be the first to receive pre-release information.</p>
                <a href='#register' className='button'><span>Pre Release Information</span></a>
              </div>
              <p className='text-bottom'>
                Register Your Details
                <svg width="1px" height="58px" viewBox="0 0 1 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="3" strokeLinecap="square">
                    <g transform="translate(-224.000000, -932.000000)" stroke="#FFFFFF">
                      <path d="M224.5,932.566406 L224.5,992.566406"></path>
                    </g>
                  </g>
                </svg>
              </p>
            </div>
          </div>
          <div className='landing__splash__slick'>
            <Slider className="gallery" {...settings}>
              <picture>
                <img src={propertyStreetView} alt='Property Street View' />
              </picture>
              <picture>
                <img src={propertyBalcony} alt='Property Balcony' />
              </picture>
            </Slider>
            <div className="gallery__price">
              <span>An Upgrade</span>
              <h5>Like No Other</h5>
            </div>
          </div>
        </section>

        <section className='landing__register' id='register'>
          <div className='landing__register__inner'>
            <div className='landing__register__content'>
              <h2>Be the first to know</h2>
              <p>The Artistry Collective – Glen Iris represents the pinnacle in urban living. This is an unprecedented opportunity to secure a new, architecturally designed, family home in one of Melbourne's best suburbs.</p>
              <ul>
                <li>Private rooftop terrace</li>
                <li>Family-sized, low maintenance homes</li>
                <li>Bespoke, high-grade fixtures & finishes</li>
                <li>Walking distance to cafes, shops, train, tram, schools and more.</li>
              </ul>
            </div>
            <div className='landing__register__form'>
              <Form />
            </div>
          </div>
        </section>

        <section className='landing__contact' id='contact'>
          <div className='landing__contact__content'>
            <h2>Have a question?</h2>
            <p>With only 5 homes available, please contact Nic today to discuss this unique and limited opportunity.</p>
            <p className='text-contact'>
              <span>Call</span>
              <a href='tel:0424 523 532'>0424 523 532</a>
            </p>
            <p className='text-contact'>
              <span>Email</span>
              <a href='mailto:nic.cuni@est8agency.com.au'>nic.cuni@est8agency.com.au</a>
            </p>
          </div>

          <footer>
            <p>Copyright © 2019 Artistry Collective   |   1600 Malvern Road, Glen Iris</p>
            <p>Website by <a href="http://www.vivegroup.com.au/" target="_blank" rel="noopener noreferrer">Vive Group</a></p>
          </footer>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
